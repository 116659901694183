import React, {useCallback} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Typeography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import UserAPI from '../api/user';
import DesktopsAPI from '../api/desktops';
import DesktopListItem from '../partials/desktopListItem';
import CreateDesktopButton from '../partials/createDesktopButton';
import Header from '../partials/header';

const DesktopsList: React.FC<{}> = () => {
  const desktops = DesktopsAPI.useDesktops();
  const user = UserAPI.useUser();

  const refreshList = useCallback(() => {
    desktops.get('');
  }, [desktops]);

  const myDesktops = desktops.data?.desktops.filter(
    d => d.user_id === user.data?.id
  );
  const sharedDesktops = desktops.data?.desktops.filter(
    d => d.user_id !== user.data?.id
  );
  const allowCreate =
    myDesktops?.length === undefined || myDesktops.length < 10;

  if (desktops.loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  // TODO: error, etc

  if (!desktops.data?.desktops.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            maxWidth: '275px',
          }}
        >
          <Typeography variant="h5">Create your first desktop</Typeography>
          <Typeography variant="subtitle2">
            A Pod Arcade Desktop is the virtual desktop that you can share with
            your friends.
          </Typeography>
          <CreateDesktopButton
            onCreate={refreshList}
            mode="button"
            fullWidth
            sx={{
              marginTop: '1rem',
            }}
          />
          <Button
            variant="text"
            color="info"
            component={Link}
            fullWidth
            target="_blank"
            href="https://www.pod-arcade.com/docs/intro/"
          >
            Read the Docs
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        {myDesktops!.map(desktop => (
          <DesktopListItem
            key={desktop.id}
            desktop={desktop}
            owner={true}
            refreshList={refreshList}
          />
        ))}
        <Tooltip
          title="You have reached the maximum number of desktops."
          open={allowCreate ? false : undefined}
        >
          <Box sx={{display: 'flex'}}>
            <CreateDesktopButton
              mode="card"
              onCreate={refreshList}
              disabled={!allowCreate}
            />
          </Box>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        {sharedDesktops!.map(desktop => (
          <DesktopListItem
            key={desktop.id}
            desktop={desktop}
            owner={false}
            refreshList={refreshList}
          />
        ))}
      </Box>
    </Box>
  );
};

const IndexPage: React.FC<{}> = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem',
      }}
    >
      <Header />
      <DesktopsList />
    </Box>
  );
};
export default IndexPage;
