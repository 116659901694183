import React from 'react';
import {Link} from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import DesktopsAPI, {IDesktop} from '../api/desktops';
import OwnerDesktopListItemMenu from './ownerDesktopListItemMenu';
import SharedDesktopListItemMenu from './sharedDesktopListItemMenu';

const DesktopListItem: React.FC<{
  desktop: IDesktop;
  owner: boolean;
  refreshList?: () => void;
}> = ({desktop, owner, refreshList}) => {
  const link = DesktopsAPI.getDesktopLink(desktop);

  return (
    <Card
      key={desktop.id}
      sx={{
        margin: '1rem',
        width: '200px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'self-start',
          '& .MuiCardHeader-title': {
            fontSize: '1rem',
            lineHeight: 1.75,
          },
        }}
        title={desktop.desktop_name}
        action={
          owner ? (
            <OwnerDesktopListItemMenu desktop={desktop} refresh={refreshList} />
          ) : (
            <SharedDesktopListItemMenu
              desktop={desktop}
              refresh={refreshList}
            />
          )
        }
      />
      <CardMedia
        sx={{
          height: '200px',
          width: '200px',
          flexGrow: 0,
        }}
        image="/img/icon-with-background.png"
      />
      <CardActions sx={{flexGrow: 0}}>
        <Button component={Link} color="secondary" fullWidth to={link}>
          Connect
        </Button>
      </CardActions>
    </Card>
  );
};
export default DesktopListItem;
