import React from 'react';

import Box from '@mui/material/Box';
import Card, {CardProps} from '@mui/material/Card';

const CenteredCard: React.FC<CardProps> = ({children, ...cardProps}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Card {...cardProps}>{children}</Card>
    </Box>
  );
};
export default CenteredCard;
