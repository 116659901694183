import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import DeleteIcon from '@mui/icons-material/Delete';
import Share from '@mui/icons-material/Share';

import {IDesktop} from '../api/desktops';
import InstallDesktopDialog from './installDesktopDialog';
import DeleteDesktopDialog from './deleteDesktopDialog';
import ShareDialog from './shareDialog';

const OwnerDesktopListItemMenu: React.FC<{
  desktop: IDesktop;
  refresh?: () => void;
}> = ({desktop, refresh}) => {
  const [installDialogOpen, setInstallDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setInstallDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <InstallDesktopIcon />
          </ListItemIcon>
          <ListItemText>Installation Instructions</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setShareDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <Share />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setDeleteDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <InstallDesktopDialog
        desktop={desktop}
        open={installDialogOpen}
        onClose={() => setInstallDialogOpen(false)}
      />
      <ShareDialog
        desktopId={desktop.id}
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
      />
      <DeleteDesktopDialog
        desktop={desktop}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => {
          refresh && refresh();
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};
export default OwnerDesktopListItemMenu;
