import {UseFetch, useFetch, CachePolicies} from 'use-http';
import {BaseModel} from './base';

export interface IDesktop extends BaseModel {
  user_id: string;
  desktop_name: string;
  auth_key: string;
}

export interface IConnectionInfo {
  broker_url: string;
  topic_prefix: string;
  username: string;
  password: string;
  user_id: string;
}

export default class DesktopsAPI {
  static useDesktops() {
    return useFetch<{desktops: IDesktop[]}>(
      '/api/v1/desktops',
      {cachePolicy: CachePolicies.CACHE_AND_NETWORK},
      []
    );
  }

  static useCreateDesktop() {
    return useFetch<IDesktop>('/api/v1/desktops', {
      method: 'POST',
      cachePolicy: CachePolicies.NETWORK_ONLY,
    });
  }

  static useUpdateDesktop(desktopId: string) {
    return useFetch<IDesktop>(`/api/v1/desktops/${desktopId}`, {
      method: 'PUT',
      cachePolicy: CachePolicies.NETWORK_ONLY,
    });
  }

  static useDeleteDesktop(desktopId: string) {
    return useFetch<IDesktop>(`/api/v1/desktops/${desktopId}`, {
      method: 'DELETE',
      cachePolicy: CachePolicies.NETWORK_ONLY,
    });
  }

  static useConnectionInfo(
    desktopId: string | undefined
  ): UseFetch<IConnectionInfo> | {loading: true} {
    if (!desktopId) {
      return {
        loading: true,
      };
    }
    return useFetch<IConnectionInfo>(
      `/api/v1/desktops/${desktopId}/connect`,
      {cachePolicy: CachePolicies.NETWORK_ONLY},
      [desktopId]
    );
  }

  // Also change in router
  static getDesktopLink(desktop: IDesktop): string {
    return `/desktop/${desktop.id}/${desktop.desktop_name}`;
  }
}
