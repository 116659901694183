import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import ShareAPI, {IShareLink} from '../api/share';

const DeleteShareLinkDialog: React.FC<{
  shareLink?: IShareLink | undefined;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}> = ({shareLink, open, onClose, onDelete}) => {
  const deleteShareLink = ShareAPI.useDeleteShareLink(
    shareLink?.desktop.id ?? '',
    shareLink?.id ?? ''
  );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Share Link</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove this share link? This will not remove
          any people that have already accepted the share.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            deleteShareLink.delete().then(onDelete);
          }}
          color="error"
          disabled={deleteShareLink.loading}
        >
          {deleteShareLink.loading ? (
            <CircularProgress variant="indeterminate" />
          ) : (
            'Delete'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteShareLinkDialog;
