import React from 'react';
import DesktopsAPI, {IDesktop} from '../api/desktops';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

const DeleteDesktopDialog: React.FC<{
  desktop: IDesktop;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}> = ({desktop, open, onClose, onDelete}) => {
  const deleteDesktop = DesktopsAPI.useDeleteDesktop(desktop.id);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Desktop</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {desktop.desktop_name}? This will also
          remove the desktop for everyone you have shared it with.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            deleteDesktop.delete().then(onDelete);
          }}
          color="error"
          disabled={deleteDesktop.loading}
        >
          {deleteDesktop.loading ? (
            <CircularProgress variant="indeterminate" />
          ) : (
            'Delete'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteDesktopDialog;
