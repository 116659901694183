import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Delete from '@mui/icons-material/Delete';
import AddLink from '@mui/icons-material/AddLink';

import ShareAPI, {IShare, IShareLink} from '../api/share';
import RemoveShareDialog from './removeShareDialog';
import useRelativeTime from '@nkzw/use-relative-time';
import DeleteShareLinkDialog from './deleteShareLink';
import AddShareLinkDialog from './addShareLinkDialog';

const ShareListItem: React.FC<{
  share: IShare;
  onRemove: () => void;
}> = ({share, onRemove}) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton onClick={onRemove}>
          <RemoveCircleOutlineIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar src={share.user.avatar} />
      </ListItemAvatar>
      <ListItemText primary={share.user.username} />
    </ListItem>
  );
};

const ShareLinkListItem: React.FC<{
  link: IShareLink;
  onDelete: () => void;
}> = ({link, onDelete}) => {
  const expiresIn = useRelativeTime(
    link.expires_at ? new Date(link.expires_at).getTime() : Date.now()
  );

  return (
    <ListItem
      secondaryAction={
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      }
    >
      <ListItemText
        primary={`${location.protocol}//${location.host}/share/${link.token}`}
        secondary={link.expires_at ? `Expires ${expiresIn}` : 'Never expires'}
      />
    </ListItem>
  );
};

const ShareDialog: React.FC<{
  desktopId: string;
  open: boolean;
  onClose: () => void;
}> = ({desktopId, open, onClose}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const shareLinks = ShareAPI.useListShareLinks(desktopId);
  const shares = ShareAPI.useListShares(desktopId);

  const [addingShareLink, setAddingShareLink] = useState(false);
  const [removingShare, setRemovingShare] = useState<IShare | null>(null);
  const [deletingShareLink, setDeletingShareLink] = useState<IShareLink | null>(
    null
  );

  useEffect(() => {
    if (open) {
      shareLinks.get();
      shares.get();
    }
  }, [open]);

  const loading = shareLinks.loading || shares.loading;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        // This is needed because otherwise it causes weird rendering glitches in Chrome in the Session
        disableRestoreFocus
      >
        <DialogTitle>Share Desktop</DialogTitle>
        <DialogContent>
          <Typography color="text.secondary">
            You can share a desktop with other people through share links. Once
            they open the link and accept the invitation, they will be able to
            connect.
          </Typography>
          <br />
          {loading ? (
            <CircularProgress variant="indeterminate" />
          ) : (
            <>
              <Typography variant="overline">People with Access</Typography>
              <List dense>
                {shares.data?.shares.map(share => (
                  <ShareListItem
                    key={share.id}
                    share={share}
                    onRemove={() => setRemovingShare(share)}
                  />
                ))}
                {shares.data?.shares.length === 0 && (
                  <ListItem>
                    <ListItemText primary="No one has access to this desktop" />
                  </ListItem>
                )}
              </List>
              <Typography variant="overline">Active Share Links</Typography>
              <List dense>
                {shareLinks.data?.share_links.map(link => (
                  <ShareLinkListItem
                    key={link.id}
                    link={link}
                    onDelete={() => setDeletingShareLink(link)}
                  />
                ))}
                {shareLinks.data?.share_links.length === 0 && (
                  <ListItem>
                    <ListItemText primary="No share links have been created" />
                  </ListItem>
                )}
                <ListItemButton
                  sx={{
                    '& .MuiTypography-root': {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  }}
                  onClick={() => setAddingShareLink(true)}
                >
                  <ListItemText
                    primary={
                      <>
                        <AddLink sx={{marginRight: '0.5rem'}} />
                        Add Share Link
                      </>
                    }
                  />
                </ListItemButton>
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <RemoveShareDialog
        desktopId={desktopId}
        userId={removingShare?.user.id ?? ''}
        open={!!removingShare}
        onClose={() => setRemovingShare(null)}
        onRemove={() => {
          setRemovingShare(null);
          shares.get(''); // Refresh shares
        }}
      />
      <AddShareLinkDialog
        desktopId={desktopId}
        open={addingShareLink}
        onClose={() => setAddingShareLink(false)}
        onAdd={() => {
          setAddingShareLink(false);
          shareLinks.get(''); // Refresh share links
        }}
      />
      <DeleteShareLinkDialog
        shareLink={deletingShareLink ?? undefined}
        open={!!deletingShareLink}
        onClose={() => setDeletingShareLink(null)}
        onDelete={() => {
          setDeletingShareLink(null);
          shareLinks.get(''); // Refresh share links
        }}
      />
    </>
  );
};
export default ShareDialog;
