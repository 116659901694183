import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FullPageLoader: React.FC<{}> = () => {
  return (
    <Backdrop open>
      <CircularProgress variant="indeterminate" />
    </Backdrop>
  );
};

export default FullPageLoader;
