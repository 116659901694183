import React from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import useRelativeTime from '@nkzw/use-relative-time';
import CenteredCard from '../partials/centeredCard';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import ShareAPI from '../api/share';
import FullPageLoader from '../partials/fullPageLoader';

const SharePage: React.FC<{}> = () => {
  const {shareId} = useParams<{shareId: string}>();
  const link = ShareAPI.useShareLink(shareId!);
  const acceptLink = ShareAPI.useAcceptShareLink(shareId!);
  const navigate = useNavigate();
  const expiresIn = useRelativeTime(
    link.data?.expires_at
      ? new Date(link.data?.expires_at).getTime()
      : Date.now()
  );

  if (link.loading) {
    return <FullPageLoader />;
  }

  if (link.response.status === 404) {
    return (
      <CenteredCard sx={{maxWidth: '600px'}}>
        <CardHeader title="Share not found" />
        <CardContent>
          The share you are trying to access does not exist. It may have expired
          or been deleted.
        </CardContent>
        <CardActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button variant="text" component={Link} to="/">
            Back
          </Button>
        </CardActions>
      </CenteredCard>
    );
  }

  return (
    <CenteredCard
      sx={{
        maxWidth: '600px',
      }}
    >
      <CardHeader
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{marginRight: '1rem'}}
              src={link.data?.owning_user.avatar}
            />
            <Typography variant="h6">
              {link.data?.owning_user.username} has shared "
              {link.data?.desktop.desktop_name}" with you
            </Typography>
          </Box>
        }
      />
      <CardContent>
        Once you accept the inivitation, the desktop will show up in your
        desktop list and you can access it at any time.
        <br />
        <br />
        {link.data?.expires_at
          ? ` This invitation will expire ${expiresIn}.`
          : null}
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="text" component={Link} to="/">
          Decline
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            acceptLink.post().then(() => {
              navigate(`/desktop/${link.data?.desktop.id}`);
            });
          }}
        >
          Accept
        </Button>
      </CardActions>
    </CenteredCard>
  );
};
export default SharePage;
