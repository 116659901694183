import React, {useCallback, useState} from 'react';
import Session from '@pod-arcade/session';
import {useNavigate, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';

import DesktopsAPI from '../api/desktops';
import UserAPI from '../api/user';
import useTitle from '../hooks/useTitle';
import FullPageLoader from '../partials/fullPageLoader';
import ShareDialog from '../partials/shareDialog';

const features = {
  mouse: true,
  keyboard: true,
  gamepads: {
    enabled: true,
    count: 4 as 1 | 2 | 3 | 4,
  },
};

const SessionPage: React.FC<{}> = () => {
  const {desktopId, desktopName} = useParams<{
    desktopId: string;
    desktopName: string;
  }>();
  const connectionInfo = DesktopsAPI.useConnectionInfo(desktopId);
  const user = UserAPI.useUser();
  const navigate = useNavigate();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  useTitle(desktopName ? `Play ${desktopName}` : 'Play');

  const onBackClick = useCallback(() => navigate('/'), []);
  const onShareClick = useCallback(() => setShareDialogOpen(true), []);

  if (user.loading || connectionInfo.loading) {
    return <FullPageLoader />;
  }

  // TODO: handle non-404 error

  if (!connectionInfo.data) {
    navigate('/');
    return null;
  }

  return (
    <>
      <Box
        sx={{
          margin: {
            lg: '1rem',
          },
          height: '100%',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <Session
          mqttUrl={connectionInfo.data.broker_url}
          mqttCredentials={connectionInfo.data}
          mqttTopicPrefix={connectionInfo.data.topic_prefix}
          features={features}
          userInfo={user.data!}
          onBackClick={onBackClick}
          onShareClick={
            connectionInfo.data.user_id === user.data!.id
              ? onShareClick
              : undefined
          }
        />
      </Box>
      <ShareDialog
        desktopId={desktopId!}
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
      />
    </>
  );
};
export default SessionPage;
