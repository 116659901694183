import {PrismLight as SyntaxHighlighter} from 'react-syntax-highlighter';
import yaml from 'react-syntax-highlighter/dist/esm/languages/prism/yaml';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import theme from 'react-syntax-highlighter/dist/esm/styles/prism/atom-dark';

SyntaxHighlighter.registerLanguage('yaml', yaml);
SyntaxHighlighter.registerLanguage('bash', bash);

const CodeArea: React.FC<{code: string; language: string}> = ({
  code,
  language,
}) => {
  return (
    <SyntaxHighlighter language={language} style={theme}>
      {code}
    </SyntaxHighlighter>
  );
};
export default CodeArea;
