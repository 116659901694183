import {useEffect} from 'react';

const useTitle = (title: string | undefined) => {
  useEffect(() => {
    const oldTitle = document.title;
    if (title) {
      document.title = 'Pod Arcade | ' + title;
    }

    return () => {
      document.title = oldTitle;
    };
  }, [title]);
};
export default useTitle;
