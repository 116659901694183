import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import {IDesktop} from '../api/desktops';
import RemoveShareDialog from './removeShareDialog';

const SharedDesktopListItemMenu: React.FC<{
  desktop: IDesktop;
  refresh?: () => void;
}> = ({desktop, refresh}) => {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setRemoveDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <RemoveCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>
      <RemoveShareDialog
        desktopId={desktop.id}
        userId="me"
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        onRemove={() => {
          refresh && refresh();
          setRemoveDialogOpen(false);
        }}
      />
    </>
  );
};
export default SharedDesktopListItemMenu;
