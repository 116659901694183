import React, {useMemo, useState} from 'react';
import {IDesktop} from '../api/desktops';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import CodeArea from './codeArea';

function getInstallText(
  desktop: IDesktop,
  method: 'env' | 'docker' | 'kubernetes'
): {content: string; language: string} {
  if (method === 'env') {
    return {
      content: [
        `# ${desktop.desktop_name}`,
        `DESKTOP_ID=${desktop.desktop_name}`,
        `CLOUD_AUTH_KEY=${desktop.auth_key}`,
      ].join('\n'),
      language: 'bash',
    };
  } else if (method === 'docker') {
    return {
      content: [
        'version: "3"',
        '',
        'services:',
        '  desktop:',
        '    image: ghcr.io/pod-arcade/desktop:main',
        '    environment:',
        `      - DESKTOP_ID=${desktop.desktop_name}`,
        `      - CLOUD_AUTH_KEY=${desktop.auth_key}`,
        '    privileged: true',
        '    volumes:',
        '      - /dev/dri:/host/dev/dri',
        '      - /dev/uinput:/host/dev/uinput',
      ].join('\n'),
      language: 'yaml',
    };
  } else if (method === 'kubernetes') {
    return {
      content: [
        'helm install --repo https://charts.pod-arcade.com \\',
        `  --set env.DESKTOP_ID=${desktop.desktop_name} \\`,
        `  --set env.CLOUD_AUTH_KEY=${desktop.auth_key} \\`,
        `  ${desktop.desktop_name} desktop-sidecar`,
      ].join('\n'),
      language: 'bash',
    };
  } else {
    throw new Error('Invalid method');
  }
}

const InstallDesktopDialog: React.FC<{
  desktop: IDesktop | undefined;
  open: boolean;
  onClose: () => void;
}> = ({desktop, open, onClose}) => {
  const [selectedMethod, setSelectedMethod] = useState<
    'env' | 'docker' | 'kubernetes'
  >('env');

  const installText = useMemo(() => {
    if (!desktop) return null;
    return getInstallText(desktop, selectedMethod);
  }, [desktop, selectedMethod]);

  if (!desktop || !installText) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Install "{desktop.desktop_name}"</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Follow the steps below to install this desktop on your server. You can
          also check out our full{' '}
          <Link href="https://www.pod-arcade.com/docs/intro/" target="_blank">
            Getting Started Guide
          </Link>
          <br />
          <br />
          <Typography variant="overline">
            Select your installation method
          </Typography>
          <br />
          <ButtonGroup fullWidth size="small">
            <Button
              onClick={() => setSelectedMethod('env')}
              variant={selectedMethod === 'env' ? 'contained' : 'outlined'}
            >
              Env
            </Button>
            <Button
              onClick={() => setSelectedMethod('docker')}
              variant={selectedMethod === 'docker' ? 'contained' : 'outlined'}
            >
              Docker Compose
            </Button>
            <Button
              onClick={() => setSelectedMethod('kubernetes')}
              variant={
                selectedMethod === 'kubernetes' ? 'contained' : 'outlined'
              }
            >
              Kubernetes
            </Button>
          </ButtonGroup>
          <CodeArea
            code={installText.content}
            language={installText.language}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
export default InstallDesktopDialog;
