import React, {useState} from 'react';
import ShareAPI, {IShareLink} from '../api/share';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const AddShareLinkDialog: React.FC<{
  desktopId: string;
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
}> = ({desktopId, open, onClose, onAdd}) => {
  const createShareLink = ShareAPI.useCreateShareLink(desktopId);

  const [expiresDuration, setExpiresDuration] = useState(-1);

  const expires_at =
    expiresDuration === -1
      ? undefined
      : new Date(Date.now() + 1000 * expiresDuration);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Share Link</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Creating a share link will allow you to share your desktop with other
          people. If an expiration time is configured, the link will
          automatically stop working after the time has elapsed.
        </DialogContentText>
        <br />
        <FormControl fullWidth sx={{marginBottom: '1rem'}}>
          <InputLabel>Expiration</InputLabel>
          <Select
            value={expiresDuration}
            label="Expiration"
            onChange={e => setExpiresDuration(e.target.value as number)}
          >
            <MenuItem value={-1}>
              <i>Never Expire</i>
            </MenuItem>
            <MenuItem value={60 * 15}>15 minutes</MenuItem>
            <MenuItem value={60 * 60}>1 Hour</MenuItem>
            <MenuItem value={60 * 60 * 24}>1 Day</MenuItem>
            <MenuItem value={60 * 60 * 24 * 7}>7 Days</MenuItem>
          </Select>
        </FormControl>
        {createShareLink.error ? (
          <>
            <br />
            <Alert severity="error">
              <b>{createShareLink.error.name}: </b>
              {createShareLink.error.message}
            </Alert>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() =>
            createShareLink
              .post({expires_at} as Pick<IShareLink, 'expires_at'>)
              .then(onAdd)
          }
          autoFocus
          disabled={createShareLink.loading}
        >
          {!createShareLink.loading ? (
            'Create'
          ) : (
            <CircularProgress variant="indeterminate" size={20} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddShareLinkDialog;
