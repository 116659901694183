import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';

import UserAPI from '../api/user';
import {Link} from '@mui/material';
import DiscordLogo from './discordLogo';

const Header: React.FC<{}> = () => {
  const user = UserAPI.useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '64px',
        padding: '0.5rem 1rem',
        marginBottom: '1rem',
      }}
    >
      <Link href="https://www.pod-arcade.com/" target="_blank">
        <img src="/img/logo-full.png" alt="Logo" height="48px" />
      </Link>
      <Box sx={{flex: 1}} />
      <IconButton
        onClick={e => setAnchorEl(e.currentTarget)}
        size="large"
        sx={{paddingLeft: 0, paddingRight: 0}}
      >
        <Avatar src={user.data?.avatar} sx={{width: '48px', height: '48px'}} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{marginTop: '0.5rem'}}
      >
        <MenuItem
          component={Link}
          href="https://www.pod-arcade.com/about/"
          target="_blank"
        >
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>About Pod Arcade</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          href="https://www.pod-arcade.com/docs/intro/"
          target="_blank"
        >
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Documentation</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          href="https://discord.gg/y8aasvEMy6"
          target="_blank"
        >
          <ListItemIcon>
            <DiscordLogo fontSize="small" />
          </ListItemIcon>
          <ListItemText>Discord</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            UserAPI.logout();
          }}
          sx={{
            minWidth: '200px',
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default Header;
