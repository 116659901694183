import {darken, createTheme} from '@mui/material/styles';

export const DarkOrange = '#FE8D06';
export const Tomato = '#EA5C45';
export const Parchment = '#F5EAD3';
export const DarkPurple = '#311A34';
export const Razzmatazz = '#DB3A74';

const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
    fontSize: 14,
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    mode: 'dark',
    text: {
      primary: '#FFF',
      secondary: darken('#FFF', 0.2),
    },
    background: {
      default: darken(DarkPurple, 0.7),
      paper: DarkPurple,
    },
    primary: {
      main: DarkOrange,
    },
    secondary: {
      main: Tomato,
    },
    info: {
      main: Parchment,
    },
    error: {
      main: 'rgb(255, 108, 97)',
    },
    divider: 'rgba(255, 255, 255, 0.10)',
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          color: darken('#FFF', 0.2),
        },
      },
    },
  },
});

export default theme;
