import {CachePolicies, useFetch} from 'use-http';
import {BaseModel} from './base';
import {IUser} from './user';
import {IDesktop} from './desktops';

export interface IShareLink extends BaseModel {
  owning_user: IUser;
  token: string;
  desktop: Omit<IDesktop, 'auth_key'>;
  expires_at: string | undefined;
}

export interface IShare extends BaseModel {
  desktop: Omit<IDesktop, 'auth_key'>;
  user: IUser;
}

class ShareAPI {
  public static useShareLink(token: string) {
    return useFetch<IShareLink>(
      `/api/v1/share-links/${token}`,
      {
        cachePolicy: CachePolicies.NETWORK_ONLY,
      },
      [token]
    );
  }

  public static useAcceptShareLink(token: string) {
    return useFetch(`/api/v1/share-links/${token}/accept`, {
      method: 'POST',
      cachePolicy: CachePolicies.NETWORK_ONLY,
    });
  }

  public static useListShareLinks(desktopId: string) {
    return useFetch<{share_links: IShareLink[]}>(
      `/api/v1/desktops/${desktopId}/share-links`,
      {
        cachePolicy: CachePolicies.NETWORK_ONLY,
      }
    );
  }

  public static useCreateShareLink(desktopId: string) {
    return useFetch<Partial<IShareLink>>(
      `/api/v1/desktops/${desktopId}/share-links`,
      {
        method: 'POST',
        cachePolicy: CachePolicies.NETWORK_ONLY,
      }
    );
  }

  public static useDeleteShareLink(desktopId: string, shareLinkId: string) {
    return useFetch<Partial<IShareLink>>(
      `/api/v1/desktops/${desktopId}/share-links/${shareLinkId}`,
      {
        method: 'DELETE',
        cachePolicy: CachePolicies.NETWORK_ONLY,
      }
    );
  }

  public static useListShares(desktopId: string) {
    return useFetch<{shares: IShare[]}>(
      `/api/v1/desktops/${desktopId}/shares`,
      {
        cachePolicy: CachePolicies.NETWORK_ONLY,
      }
    );
  }

  /**
   * Removes a share for a desktop. If you are removing your own share, pass in 'me' for userId.
   */
  public static useRemoveShare(desktopId: string, userId: 'me' | string) {
    return useFetch(`/api/v1/desktops/${desktopId}/shares/${userId}`, {
      method: 'DELETE',
      cachePolicy: CachePolicies.NETWORK_ONLY,
    });
  }
}
export default ShareAPI;
