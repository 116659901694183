import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import UserAPI from './api/user';
import theme from './theme';

import './App.css';

const App: React.FC<{}> = () => {
  const user = UserAPI.useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = location.pathname === '/login';

  useEffect(() => {
    if (isLogin) {
      // Already logged in
      if (!user.loading && !user.error) {
        navigate('/');
      }
    } else {
      if (
        user.error &&
        (user.error.message === 'Unauthorized' || user.response.status === 401)
      ) {
        console.log('login', {location});
        navigate(
          `/login${
            location.pathname !== '/' ? `?redirect=${location.pathname}` : ''
          }`
        );
      }
    }
  }, [user, user.error, location]);

  // Short circuit if we're on the login page
  if (isLogin) {
    return <Outlet />;
  }

  if (user.loading) {
    // TODO: spinner
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  if (user.error && location.pathname !== '/login') {
    return <div>Error: {user.error.message}</div>;
  }

  return (
    <UserAPI.UserContext.Provider value={user.data!}>
      <Outlet />
    </UserAPI.UserContext.Provider>
  );
};

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};
