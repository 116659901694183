import React, {useContext} from 'react';
import {useFetch} from 'use-http';
import {BaseModel} from './base';

export interface IUser extends BaseModel {
  subject: string;
  avatar: string;
  username: string;
  email: string;
}

export default class UserAPI {
  static UserContext = React.createContext<IUser | null>(null);

  static useUser() {
    const ctx = useContext(UserAPI.UserContext);
    return useFetch<IUser>('/api/v1/user/me', {data: ctx}, []);
  }

  static login(provider = 'discord', redirect?: string) {
    location.href = `/login/${provider}${
      redirect ? `?redirect=${redirect}` : ''
    }`;
  }

  static logout() {
    location.href = '/login/logout';
  }
}
