import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import ShareAPI from '../api/share';

const RemoveShareDialog: React.FC<{
  desktopId: string;
  userId: string;
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
}> = ({desktopId, userId, open, onClose, onRemove}) => {
  const removeShare = ShareAPI.useRemoveShare(desktopId, userId);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove Access to Desktop</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {userId === 'me'
            ? `Are you sure you want to remove this desktop from your list?
          This will prevent you from accessing it without the owner sharing it
          with you again.`
            : 'Are you sure you want to remove access for this user?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            removeShare.delete().then(onRemove);
          }}
          color="error"
          disabled={removeShare.loading}
        >
          {removeShare.loading ? (
            <CircularProgress variant="indeterminate" />
          ) : (
            'Remove'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoveShareDialog;
