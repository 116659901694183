import React from 'react';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import {darken, lighten} from '@mui/material/styles';

import CenteredCard from '../partials/centeredCard';
import DiscordLogo from '../partials/discordLogo';
import theme from '../theme';
import UserAPI from '../api/user';
import useTitle from '../hooks/useTitle';
import {useLocation} from 'react-router-dom';
import QueryString from 'qs';

const discordColor = '#4F5CE8';
const hover = lighten(discordColor, 0.1);
const linksColor = darken(theme.palette.primary.main, 0.2);

const LoginPage: React.FC<{}> = () => {
  useTitle('Login');
  const query = useLocation().search;
  const redirect = QueryString.parse(query.substring(1)).redirect as
    | string
    | undefined;

  return (
    <CenteredCard>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              padding: '1rem',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <img width="250px" src="/img/logo-full.png" />
          </Box>
          <Box
            sx={{
              padding: '2rem 0',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: discordColor,
                color: theme.palette.getContrastText(discordColor),
                '&:hover': {
                  backgroundColor: hover,
                },
              }}
              size="large"
              startIcon={<DiscordLogo />}
              onClick={() => UserAPI.login('discord', redirect)}
            >
              Login with Discord
            </Button>
          </Box>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link
          href="https://www.pod-arcade.com/privacy/"
          underline="none"
          fontSize={12}
          color={linksColor}
          target="_blank"
        >
          Privacy Policy
        </Link>
        <Divider
          dir="vertical"
          flexItem
          sx={{
            borderColor: linksColor,
            borderWidth: '0 0.5px 0 0',
            marginLeft: '0.5rem',
          }}
        />
        <Link
          href="https://www.pod-arcade.com/community/"
          underline="none"
          fontSize={12}
          color={linksColor}
          target="_blank"
        >
          Community Guidelines
        </Link>
      </CardActions>
    </CenteredCard>
  );
};
export default LoginPage;
