import React, {useState} from 'react';
import DesktopsAPI from '../api/desktops';

import Button, {ButtonProps} from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import Add from '@mui/icons-material/Add';
import InstallDesktopDialog from './installDesktopDialog';

const CreateDesktopButton: React.FC<
  {onCreate: () => void; mode: 'card' | 'button'} & ButtonProps
> = ({onCreate, mode, ...props}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [desktopName, setDesktopName] = useState('');
  const createdDesktop = DesktopsAPI.useCreateDesktop();

  const DNS_NAME_REGEX = new RegExp(
    '^[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?$',
    'g'
  );
  const valid = desktopName.length === 0 || DNS_NAME_REGEX.test(desktopName);
  const disabled = desktopName.length === 0 || createdDesktop.loading || !valid;

  const done = () => {
    setModalOpen(false);
    onCreate();
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Add />}
        {...props}
        onClick={() => setModalOpen(true)}
        sx={{
          ...(mode === 'card'
            ? {
                width: '200px',
                margin: '1rem',
                minHeight: '312px',
              }
            : {}),
        }}
      >
        Create Desktop
      </Button>
      {!createdDesktop.data ? (
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <DialogTitle>Create Desktop</DialogTitle>
          <DialogContent>
            <DialogContentText>
              A Pod Arcade Desktop is the virtual desktop that you can share
              with your friends. Once this desktop has been created, we will
              tell you how to install it on your server
            </DialogContentText>
            <br />
            <TextField
              sx={{
                marginBottom: valid ? '23px' : '0px',
              }}
              autoFocus
              label="Desktop Name"
              fullWidth
              variant="standard"
              value={desktopName}
              error={!valid}
              helperText={
                !valid
                  ? "Must contain only lowercase letters, numbers, and hyphens, and can't start and end with a hypen."
                  : ''
              }
              onChange={e => setDesktopName(e.target.value)}
            />
            {createdDesktop.error ? (
              <>
                <br />
                {createdDesktop.response.status === 409 ? (
                  <Alert severity="warning">
                    There is already a desktop with that name
                  </Alert>
                ) : (
                  <Alert severity="error">
                    <b>{createdDesktop.error.name}: </b>
                    {createdDesktop.error.message}
                  </Alert>
                )}
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button
              onClick={() => createdDesktop.post({desktop_name: desktopName})}
              autoFocus
              disabled={disabled}
            >
              {!createdDesktop.loading ? (
                'Create'
              ) : (
                <CircularProgress variant="indeterminate" size={20} />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {createdDesktop.data ? (
        <InstallDesktopDialog
          desktop={createdDesktop.data}
          open={modalOpen}
          onClose={done}
        />
      ) : null}
    </>
  );
};
export default CreateDesktopButton;
