import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  RouterProvider,
  createMemoryRouter,
  createBrowserRouter,
} from 'react-router-dom';
import ReactGA from 'react-ga4';

import App from './App';
import IndexPage from './pages';
import LoginPage from './pages/login';
import SessionPage from './pages/session';
import SharePage from './pages/share';

ReactGA.initialize('G-5DLFDWH3CZ');

// If running standalone (iOS or Chrome) use a memory router so it stays fullscreen.
const routerFactory =
  (('standalone' in window.navigator && window.navigator.standalone === true) ||
    window.matchMedia('(display-mode: standalone)').matches) &&
  location.pathname === '/' //don't use memory router if we're already on a subpage
    ? createMemoryRouter
    : createBrowserRouter;

const router = routerFactory([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        // Also change DesktopsAPI.getDesktopLink
        path: '/desktop/:desktopId/:desktopName',
        element: <SessionPage />,
      },
      {
        // Also change DesktopsAPI.getDesktopLink
        path: '/desktop/:desktopId',
        element: <SessionPage />,
      },
      {
        path: '/share/:shareId',
        element: <SharePage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<RouterProvider router={router} />);
